import React, { useState } from "react";
import { Button, Modal } from "antd";

import { Translate } from "react-localize-redux";

import "./DriverIdentificationAssignModal.css";
import { UserResponseInfo } from "../../../types/user/userResponse.types";

type AssignModalProps = {
  assignModal: boolean;
  vehicleName: string;
  selectedDriver?: UserResponseInfo | null;
  driverName?: string;
  onHandleCancel: () => void;
  onHandleConfirm: () => void;
};

const DriverIdentificationAssignModal: React.FC<AssignModalProps> = ({
  assignModal,
  vehicleName,
  selectedDriver,
  driverName,
  onHandleCancel,
  onHandleConfirm,
}) => {

  const formatName = (fullName: string) => {
    const [ firstName, lastName ] = fullName.split(" ");
    const lastNameInitial = lastName ? lastName.slice(0, 1).toUpperCase() : "";
    return `${firstName} ${lastNameInitial}.`
  }

  return (
    <Modal
      title={
        <Translate id="driverIdentification.vehicleAssignmentModalTitle" />
      }
      centered
      open={assignModal}
      onOk={onHandleConfirm}
      onCancel={onHandleCancel}
      className="driver-identification-assign-modal"
      okText={<Translate id="driverIdentification.confirm" />}
    >
      {driverName ? (
        <p>
          <Translate
            id="driverIdentification.vehicleAssignmentModalText1"
            data={{ vehicleName: vehicleName, driverName: formatName(driverName)}}
          />
        </p>
      ) : (
        <p>
          <Translate
            id="driverIdentification.vehicleAssignmentModalText2"
            data={{
              vehicleName: vehicleName,
              firstName: selectedDriver?.firstName,
              lastName: selectedDriver?.lastName.slice(0, 1),
            }}
          />
        </p>
      )}
    </Modal>
  );
};

export default DriverIdentificationAssignModal;
