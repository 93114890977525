import React from "react";
import PropTypes from "prop-types";
import {connect, useSelector} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {allowedRoles, isAuthorized, isViewer} from "../../managers/authManager";
import {roles} from "../../models/Role";
import {packages} from "../../models/Package";
import {Translate} from "react-localize-redux";
import { findEngineTypeById } from "../../models/EngineType";

const VehicleTable = props => {

    const {
        vehicles,
        total,
        selected,
        page,
        rowsPerPage,
        onView,
        onEdit,
        onSelect,
        onSelectAll,
        handleChangePage,
        handleChangeRowsPerPage,
        onHistory,
        onCalibrate,
        onArchive,
        onUnArchive,
        me,
        onGenerateQrCode
    } = props;

    const viewer = isViewer(me);

    const columnInformations = [
        {title: <Translate id="vehicle.table.name"/>, key: "name"},
        {title: <Translate id="vehicle.table.licensePlate"/>, key: "licensePlate"},
        {title: <Translate id="vehicle.table.model"/>, key: "model"},
        {title: <Translate id="vehicle.table.description"/>, key: "description"},
        {title: <Translate id="vehicle.table.mileage"/>, key: "mappedMileage"},
        {title: <Translate id="vehicle.table.type"/>, key: "mappedType"},
        {title: <Translate id="vehicle.table.groups"/>, key: "groups", type: TableTypes.group},
        {title: <Translate id="vehicle.table.drivebox"/>, key: "drivebox.name"},
    ];

    if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) 
        columnInformations.push( {title: <Translate id="vehicle.table.costCenter"/>, key: "costcenterKey"});

    if(isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor])){
        columnInformations.splice(7, 0, 
            {title: <Translate id="vehicle.table.engineType"/>, key: "engineType", type: TableTypes.engineType},
            {title: <Translate id="vehicle.table.propulsionStatus"/>, key: "fuelStatus"},
        )}; 

    const actions = [
        {title: <Translate id="vehicle.table.view"/>, event: onView},
        {title: <Translate id="vehicle.table.edit"/>, event: onEdit},
        {title: <Translate id="vehicle.table.history"/>, event: onHistory}
    ];

    if (me.canCalibrate)
        actions.push({title: <Translate id="vehicle.table.calibration"/>, event: onCalibrate});

    actions.push({
        title: <Translate id="vehicle.table.archive"/>,
        event: onArchive,
        comparator: new Comparator(["archive"], (archive) => !archive),
    });

    actions.push(
        {
            title: <Translate id="vehicle.table.deArchive"/>,
            event: onUnArchive,
            comparator: new Comparator(["archive"], (archive) => archive),
        }
    );

    if (isAuthorized(me, allowedRoles(roles.Admin), [packages.DriverIdentification])) {
        actions.push({title: <Translate id="driverIdentification.generateQrCode"/>, event: onGenerateQrCode }
        );
    }
    
    const viewerActions = [
        {title: "History", event: onHistory},
    ];

    const mappedVehicles = vehicles.map(v => {
        const {archivedAt, type, fuelOrBatteryStateAbsolut} = v;
        const engineUnit = findEngineTypeById(v.engineType)?.name === "Fuel" ? "L" : "kWh";
        v.archive = Boolean(archivedAt);
        v.mappedType = type === 0 ? "Company" : type === 1 ? "Private" : type === 2 ? "Pool" : "";
        v.mappedMileage = v.mileageInMeters ? Math.round(v.mileageInMeters / 10) / 100 + " km" : "";
        if (fuelOrBatteryStateAbsolut) {
            v.fuelStatus = (v?.tankSize - v?.fuelOrBatteryStateAbsolut) + engineUnit;
        } else {
            v.fuelStatus = "Keine Angabe";
        }
        return v;
    });

    return (
        <GenericTable
            data={mappedVehicles}
            columnInformations={columnInformations}
            actions={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? viewerActions : actions}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onView}
            onRowClick={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onEdit}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onSelectAllClick={onSelectAll}
            onSelect={onSelect}
            numSelected={selected}
            showActions
            showCheckboxes={!viewer}
        />
    );
};

VehicleTable.defaultProps = {
    page: 0,
};

// VehicleTable.propTypes = {
//     page: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     onSelect: PropTypes.func.isRequired,
//     onSelectAll: PropTypes.func.isRequired,
//     onEdit: PropTypes.func.isRequired,
//     onView: PropTypes.func.isRequired,
//     onHistory: PropTypes.func.isRequired,
//     onArchive: PropTypes.func.isRequired,
//     onUnArchive: PropTypes.func.isRequired,
//     onCalibrate: PropTypes.func.isRequired,
// };

function mapStateToProp(state) {
    return {
        vehicles: state.vehicles.entities,
        costcenters: state.costcenters.entities,
        selected: state.vehicles.selected,
        total: state.vehicles.total,
        me: state.users.me,
    };
}

export default connect(mapStateToProp)(VehicleTable);
