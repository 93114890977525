import { mapQueryParams } from "../managers/apiManager";
import { languageKey } from "../managers/localStorageManager";

const baseUrl = window.location.origin + "/api/";
const axios = require("axios");
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    "Accept-Language": JSON.parse(localStorage.getItem(languageKey)),
    "Content-Type": "application/json; charset=utf-8",
  },
});

//#region Costcenters
export const getCostcenters = (params = {}) => {
  // = async
  return instance.get("Costcenter" + mapQueryParams(params));
};

export const getCostcenterById = async (costcenterid) => {
  return instance.get("Costcenter/" + costcenterid);
};

export const getCurrentlyLoggedinCostcenter = async () => {
  return instance.get("Costcenter/current");
};

export const addCostcenter = (bodyParams) => {
  return instance.post("Costcenter/", JSON.stringify(bodyParams));
};

export const updateCostcenter = (bodyParams) => {
  return instance.put(
    "Costcenter/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const updateCostcenterMandator = async (bodyParams) => {
  return instance.put("Mandators", JSON.stringify(bodyParams));
};
/*
export const updateCostcenterNotificationOfMandator = async bodyParams => {
    return instance.put("Mandators/Notification", JSON.stringify(bodyParams));
};*/

export const updateCostcenters = (bodyParams) => {
  return instance.patch("Costcenter/", JSON.stringify(bodyParams));
};

export const deleteCostcenter = async (bodyParams) => {
  return instance.delete("Costcenter/" + bodyParams.id);
};

export const updatCostcenterArchive = async (bodyParams) => {
  return instance.post(
    "/Costcenter/" + bodyParams.id + "/ToggleArchive",
    JSON.stringify(bodyParams)
  );
};

export const updatCostcentersArchive = async (bodyParams) => {
  return instance.post("/Costcenter/ToggleArchive", JSON.stringify(bodyParams));
};

//#region Users
export const getUsers = async (params = {}) => {
  return instance.get("Users" + mapQueryParams(params));
};

export const getUserById = async (userid) => {
  return instance.get("Users/" + userid);
};

export const getCurrentlyLoggedinUser = async () => {
  return instance.get("Users/current");
};

export const addUser = async (bodyParams) => {
  return instance.post("Users/", JSON.stringify(bodyParams));
};

export const updateUser = async (bodyParams) => {
  return instance.put("Users/" + bodyParams.id, JSON.stringify(bodyParams));
};

export const updateUserMandator = async (bodyParams) => {
  return instance.put("Mandators", JSON.stringify(bodyParams));
};

export const updateUserNotificationOfMandator = async (bodyParams) => {
  return instance.put("Mandators/Notification", JSON.stringify(bodyParams));
};

export const updateUsers = async (bodyParams) => {
  return instance.patch("Users/", JSON.stringify(bodyParams));
};

export const deleteUser = async (bodyParams) => {
  return instance.delete("Users/" + bodyParams.id);
};

export const updatUserArchive = async (bodyParams) => {
  return instance.post(
    "/Users/" + bodyParams.id + "/ToggleArchive",
    JSON.stringify(bodyParams)
  );
};

export const updatUsersArchive = async (bodyParams) => {
  return instance.post("/Users/ToggleArchive", JSON.stringify(bodyParams));
};

export const changePassword = async (bodyParams) => {
  return instance.put("Users/ChangePassword/", JSON.stringify(bodyParams));
};

export const changePasswordForUser = async (bodyParams) => {
  return instance.put(
    "Users/" + bodyParams.id + "/ChangePassword/",
    JSON.stringify(bodyParams)
  );
};

export const resetPassword = async (bodyParams) => {
  return instance.post(
    "Users/" + bodyParams.userId + "/ResetPassword/",
    JSON.stringify(bodyParams)
  );
};

export const resetMomentPassword = async (bodyParams) => {
  return instance.put("Users/ResetMomentPassword", JSON.stringify(bodyParams));
};

export const forgotPassword = async (bodyParams) => {
  return instance.post(
    "Authentication/PasswordForgotten",
    JSON.stringify(bodyParams)
  );
};

//Roles
export const getRoles = async () => {
  return instance.get("Roles/");
};

//More boxes email
export const sendOrderMoreBoxesEmailRequest = async (bodyParams) => {
  return instance.post("Users/SendMoreBoxOrder", JSON.stringify(bodyParams));
}

//#endregion

//#region Vehicle
export const getVehicles = async (params = {}) => {
  return instance.get("Vehicles" + mapQueryParams(params));
};

export const getVehicleLastPositions = async () => {
  return instance.get("Vehicles/LastPosition");
};

export const getVehicleById = async (vehicleid) => {
  return instance.get("Vehicles/" + vehicleid);
};

export const addVehicle = async (bodyParams) => {
  return instance.post("Vehicles", JSON.stringify(bodyParams));
};

export const updateVehicle = async (bodyParams) => {
  return instance.put("Vehicles/" + bodyParams.id, JSON.stringify(bodyParams));
};

export const updateVehicles = async (bodyParams) => {
  return instance.patch("Vehicles/", JSON.stringify(bodyParams));
};

export const updateVehicleArchiveToggle = async (bodyParams) => {
  return instance.post(
    "Vehicles/" + bodyParams.id + "/ToggleArchive",
    JSON.stringify(bodyParams)
  );
};

export const updateVehiclesArchiveToggle = async (bodyParams) => {
  return instance.post("Vehicles/ToggleArchive", JSON.stringify(bodyParams));
};

export const deleteVehicle = async (id) => {
  return instance.delete("Vehicles/" + id);
};

export const addVehicleDriver = async (bodyParams) => {
  return instance.post(
    "Vehicles/" + bodyParams.vehicleId + "/drivers/" + bodyParams.userId
  );
};

export const deleteVehicleDriver = async (vehicleId) => {
  return instance.delete(
    "Vehicles/" + vehicleId + "/Drivers"
    // "Vehicles/" + bodyParams.vehicleId + "/drivers/" + bodyParams.userId
  );
};

export const getOpenTrack = async (vehicleId) => {
  return instance.get("Vehicles/" + vehicleId + "/OpenTrack");
};

//calibration
export const getCalibrations = async (vehicleId) => {
  return instance.get("Vehicles/" + vehicleId + "/Calibrations");
};

export const addCalibration = async (bodyParams) => {
  return instance.post(
    "Vehicles/" + bodyParams.vehicleId + "/Calibrations",
    JSON.stringify(bodyParams)
  );
};

export const deleteCalibration = async (params) => {
  return instance.delete(
    "Vehicles/" + params.vehicleId + "/Calibrations/" + params.calibrationId
  );
};

export const getTracksByVehicle = async (params) => {
  return instance.get(
    "Vehicles/" + params.vehicleId + "/Tracks" + mapQueryParams(params)
  );
};

//#endregion

//#region inspection
export const getInspection = async (vehicleId) => {
  return instance.get("VehicleInspectionHistory/" + vehicleId);
};

export const getNextInspection = async (vehicleId) => {
  return instance.get(
    "VehicleInspectionHistory/" + vehicleId + "/NextInspection"
  );
};

export const deleteInspection = async (vehicleId) => {
  return instance.delete("VehicleInspectionHistory/" + vehicleId);
};

export const addInspection = async (bodyParams) => {
  return instance.post("VehicleInspectionHistory/", JSON.stringify(bodyParams));
};

//#endregion

//#region vehicle service
export const getVehicleService = async (vehicleId) => {
  return instance.get("VehicleServiceHistory/" + vehicleId);
};

export const getNextVehicleService = async (vehicleId) => {
  return instance.get("VehicleServiceHistory/" + vehicleId + "/NextService");
};

export const deleteVehicleService = async (vehicleId) => {
  return instance.delete("VehicleServiceHistory/" + vehicleId);
};

export const addVehicleService = async (bodyParams) => {
  return instance.post("VehicleServiceHistory/", JSON.stringify(bodyParams));
};

//#endregion

//#region Trips
export const getTrips = async (params = {}) => {
    params.GroupFilterOptions = 1
  return instance.get("Trips" + mapQueryParams(params));
};

export const getTripById = async (tripId) => {
  return instance.get("Trips/" + tripId);
};

export const addTrip = async (bodyParams) => {
  return instance.post("Trips", JSON.stringify(bodyParams));
};

export const lockTrips = async (bodyParams) => {
  return instance.put("Trips/Lock", JSON.stringify(bodyParams));
};

export const unlockTrips = async (bodyParams) => {
  return instance.put("Trips/Unlock", JSON.stringify(bodyParams));
};

export const setReadyToExportTrips = async (bodyParams) => {
  return instance.put("Trips/SetReadyToExport", JSON.stringify(bodyParams));
};

export const setReadyToExportTripsByMonth = async (bodyParams) => {
  return instance.put(
    "Trips/SetReadyToExportByMonth",
    JSON.stringify(bodyParams)
  );
};

export const updateTrip = async (bodyParams) => {
  return instance.put("Trips/" + bodyParams.tripId, JSON.stringify(bodyParams));
};

export const updateTrips = async (bodyParams) => {
  return instance.put("Trips", JSON.stringify(bodyParams));
};

export const deleteTrip = async (tripId) => {
  return instance.delete("Trips/" + tripId);
};

export const getTrackOfTrip = async (tripId) => {
  return instance.get("Trips/" + tripId + "/Track");
};

export const getMileageOfTrip = async (tripId) => {
  return instance.get("Trips/" + tripId + "/Mileage");
};

export const splitTrip = async ({ data: {splitData, filter} }) => {
  return instance
    .put("Trips/" + splitData.tripId + "/Split", JSON.stringify(splitData))
    .then((r) => instance.get("Trips" + mapQueryParams(filter))); //force reload
};

export const mergeTrips = async ({ data: { tripIds, filter} }) => {
  return instance
    .post(
      "Trips/Merge",
      JSON.stringify({
        tripIds: tripIds,
        isPreview: false,
      })
    )
    .then((r) => instance.get("Trips" + mapQueryParams(filter))); //force reload
};

export const mergeTripsPreview = async (bodyParams) => {
  return instance.post(
    "Trips/Merge",
    JSON.stringify({ tripIds: bodyParams, isPreview: true })
  );
};

export const getUnrealisticTripsRequest = async (params) => {
  return instance.get("Trips/Suspicious" + mapQueryParams(params));
};

export const getMisMatchTripsRequest = async (params) => {
  return instance.get("Trips/AddressDoNotMatchWithPreviousOrNextTrip" + mapQueryParams(params));
};

//#endregion


//#region Logs
export const getLogsDataAsync = async (params) => {
  return instance.get("Logs" + mapQueryParams(params));
};

//#endregion

//#region Time Recording
export const getTimeRecording = async (params = {}) => {
  return instance.get("TimeLog" + mapQueryParams(params));
};

export const getTimeRecordingById = async (timeLogId) => {
  return instance.get("TimeLog/" + timeLogId);
};

export const addTimeRecording = async (bodyParams) => {
  return instance.post("TimeLog", JSON.stringify(bodyParams));
};

export const updateTimeRecording = async (bodyParams) => {
  return instance.put("TimeLog/Event", JSON.stringify(bodyParams));
};

export const deleteTimeRecording = async (timeLogEventid) => {
  return instance.delete("TimeLog/Event/" + timeLogEventid);
};

//#endregion
//#region Driveboxes
export const getDriveboxes = async (params = {}) => {
  return instance.get("Driveboxes" + mapQueryParams(params));
};

export const getDriveboxById = async (driveboxid) => {
  return instance.get("Driveboxes/" + driveboxid);
};

export const updateDrivebox = async (bodyParams) => {
  return instance.put(
    "Driveboxes/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const getPluggedEvents = async (driveboxid) => {
  return instance.get("Driveboxes/" + driveboxid + "/PluggedEvents");
};

//#endregion

//#region Groups
export const getGroups = async (parentId) => {
  const parentStr = parentId ? "?parentId=" + parentId : "";
  return instance.get("Groups" + parentStr);
};

export const getGroupById = async (groupId) => {
  return instance.get("Groups/" + groupId);
};

export const addGroup = async (bodyParams) => {
  return instance.post("Groups", JSON.stringify(bodyParams));
};

export const updateGroup = async (bodyParams) => {
  return instance.put("Groups/" + bodyParams.id, JSON.stringify(bodyParams));
};

export const deleteGroup = async (bodyParams) => {
  return instance.delete("Groups/" + bodyParams.id);
};

export const moveGroup = async ({ id, parentId }) => {
  return instance.post(
    "Groups/" + id + "/move",
    JSON.stringify({ parentId: parentId })
  );
};

export const getTree = async () => {
  return instance.get("Groups/Tree");
};

export const getTreesCurrentUser = async () => {
  return instance.get("Groups/CurrentUser");
};

//#endregion

//#region Order

export const getOrder = async (data) => {
  return instance.post("Orders", JSON.stringify(data));
};

//#endregion

//#region authentication
export const getAuthentication = async (bodyParams) => {
  return instance.post("Authentication", JSON.stringify(bodyParams));
};

export const getLogout = async () => {
  return instance.delete("Authentication");
};
//#endregion

//#region Mandators
export const getMandators = async (params = {}) => {
  return instance.get("Admin/Mandators" + mapQueryParams(params));
};

export const getMandatorById = async (mandatorId) => {
  return instance.get("Admin/Mandators/" + mandatorId);
};

export const addMandator = async (bodyParams) => {
  return instance.post("Admin/Mandators", JSON.stringify(bodyParams));
};

export const updateMandator = async (bodyParams) => {
  return instance.put(
    "Admin/Mandators/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const deleteMandator = async (bodyParams) => {
  return instance.delete("Admin/Mandators/" + bodyParams.id);
};

export const getMandatorDriveboxes = async (params = {}) => {
  return instance.get("Admin/Driveboxes" + mapQueryParams(params));
};

export const getDriveBoxHistory = async (driveboxid) => {
  return instance.get("Driveboxes/" + driveboxid + "/History");
};

export const getDriveBoxLastSignal = async (imei) => {
  return instance.get("Driveboxes/" + imei + "/LastPosition");
}

export const getVehicleRegistration = async (userId) => {
  return instance.get("Vehicles/" + userId + "/VehicleRegistration");
};

export const getLastLogIns = async (userId) => {
  return instance.get("Users/" + userId + "/LastLogIn" );
};

export const getMandatorDriveboxesByImei = async (imei) => {
  return instance.get("Admin/Driveboxes/ByImei?imei=" + imei);
};

export const addMandatorDrivebox = async (bodyParams) => {
  return instance.post("Admin/Driveboxes/", JSON.stringify(bodyParams));
};

export const updateMandatorDrivebox = async (bodyParams) => {
  return instance.put(
    "Admin/Driveboxes/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const deleteMandatorDrivebox = async (bodyParams) => {
  return instance.delete(
    "Admin/Mandators/" +
      bodyParams.mandatorId +
      "/driveboxes/" +
      bodyParams.driveboxId
  );
};

export const updateMandatorDriveboxArchive = async (bodyParams) => {
  return instance.post(
    "/Admin/Driveboxes/" + bodyParams.id + "/ToggleArchive",
    JSON.stringify(bodyParams)
  );
};

export const getMandatorUsers = async (params = {}) => {
  return instance.get("Admin/Users" + mapQueryParams(params));
};

export const addMandatorUsers = async (bodyParams) => {
  console.log("add Mandator Users");
  return instance.post("Admin/Users", JSON.stringify(bodyParams));
};

export const getMandatorSuperUsers = async (params = {}) => {
  return instance.get("Admin/SuperAdminUsers?searchTerm=" + params.searchTerm);
};

export const addMandatorSuperUser = async (bodyParams) => {
  return instance.post("Admin/SuperAdminUsers", JSON.stringify(bodyParams));
};

export const updateMandatorSuperUser = async (bodyParams) => {
  return instance.put(
    "Admin/SuperAdminUsers/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const deleteMandatorSuperUser = async (bodyParams) => {
  return instance.delete("Admin/SuperAdminUsers/" + bodyParams.id);
};

export const updateMandatorUserChangePassword = async (bodyParams) => {
  return instance.put(
    "/Admin/SuperAdminUsers/" + bodyParams.id + "/ChangePassword",
    JSON.stringify(bodyParams)
  );
};

export const updateMandatorArchive = async (mandatorId) => {
  return instance.post("/Admin/Mandators/" + mandatorId + "/ToggleArchive");
};

export const surrogateMandator = async (mandatorId) => {
  return instance.post("/Admin/Mandators/" + mandatorId + "/Surrogate");
};

export const surrogateMandatorUser = async (userId) => {
  return instance.post("/Admin/Users/" + userId + "/Surrogate");
};

export const sendWelcomeMail = async (bodyParams) => {
  return instance.post(
    "Admin/Users/" + bodyParams.id + "/SendWelcomeMail/",
    JSON.stringify(bodyParams)
  );
};

//#endregion

//#region RowData
export const getMandatorDriveboxPositions = async (params) => {
  return instance.get("/Admin/DriveboxPositions" + mapQueryParams(params));
};

export const getMandatorDriveboxEvents = async (params) => {
  return instance.get("/Admin/DriveboxEvents" + mapQueryParams(params));
};

export const getMandatorTrips = async (params) => {
  return instance.get("/Admin/Trips" + mapQueryParams(params));
};

//#endregion

//#region Livelocation
export const getLastLocationVehicles = async (params = {}) => {
  return instance.get(
    "LiveLocations/VehiclePositions" + mapQueryParams(params)
  );
};
//#endregion

//#region Histories
export const getHistory = async (params = {}) => {
  return instance.get("Histories/" + params.id + mapQueryParams(params));
};
//#endregion

//#region services
export const getServices = async () => {
  return instance.get("Services");
};
//#endregion

//#region reports
export const getTripMileageReport = async (params = {}) => {
  return instance.get("Trips/Report/Mileage" + mapQueryParams(params));
};

export const getTripReport = async (params = {}) => {
  return instance.get("Trips/Report" + mapQueryParams(params));
};

export const getTripDaysReport = async (params = {}) => {
  return instance.get("Trips/Report/Days" + mapQueryParams(params));
};

export const getTripVehiclesReport = async (params = {}) => {
  return instance.get("Trips/Report/Vehicles" + mapQueryParams(params));
};

export const getTripUsersReport = async (params = {}) => {
  return instance.get("Trips/Report/Users" + mapQueryParams(params));
};

export const getTripIdleTimeReport = async (params = {}) => {
  //api moved idleTime report to vehicle.
  return instance.get(
    "Vehicles/" + params.id + "/IdleTime" + mapQueryParams(params)
  );
};

export const getVehicleReport = async (params = {}) => {
  return instance.get("Vehicles/Report" + mapQueryParams(params));
};

export const getDriveboxReport = async (params = {}) => {
  return instance.get("Driveboxes/Report" + mapQueryParams(params));
};

//#endregion

//#region scheduled reports
export const getScheduledReports = async (params = {}) => {
  return instance.get("Reports" + mapQueryParams(params));
};

export const getScheduledReportById = async (id) => {
  return instance.get("Reports/" + id);
};

export const addScheduledReport = async (bodyParams = {}) => {
  return instance.post("Reports", JSON.stringify(bodyParams));
};

export const updateScheduledReport = async (bodyParams = {}) => {
  return instance.put("Reports/" + bodyParams.id, JSON.stringify(bodyParams));
};

export const deleteScheduledReport = async (bodyParams = {}) => {
  return instance.delete("Reports/" + bodyParams.id);
};
//#endregion

//#region common
export const getVersion = async () => {
  return instance.get("Version");
};

//#endregion

//#region geofences
export const updateGeofenceVehicles = async (bodyParams) => {
  return instance.put(
    "Geofences/updateGeofenceVehicles/" + bodyParams.id,
    JSON.stringify(bodyParams.geofenceVehiclesView)
  );
};

export const getGeofencesNameId = async (params) => {
  return instance.get("geofences/partialEntity" + mapQueryParams(params));
};

export const getAssignedGeofenceVehicles = async (params) => {
  return instance.get(
    "geofences/assignedGeofenceVehicles/" + params.geofenceId
  );
};

export const getUnAssignedGeofenceVehicles = async (params) => {
  return instance.get(
    "geofences/unAssignedGeofenceVehicles/" + params.geofenceId
  );
};

export const getGeofencesOfCurrentVehicle = async (params) => {
  return instance.get("geofences/vehicles/" + params.vehicleId);
};

export const getGeofencesOfCurrentTrip = async (params) => {
  return instance.get("geofences/trips/" + params.tripId);
};

export const getGeofencesEventsOfCurrentTrip = async (params) => {
  return instance.get("geofences/events/" + params.tripId);
};

export const getGroupedGeofencesEventsOfCurrentTrip = async (params) => {
  return instance.get("geofences/grouped/events/" + params.tripId);
};

export const getGeofencesEventTrips = async (params) => {
  return instance.get("geofences/events/trips" + mapQueryParams(params));
};

export const getGeofenceAnalysisEvents = async (params) => {
    return instance.get("geofences/getGeofenceAnalysisEvents" + mapQueryParams(params));
};

export const getGeofenceSubscriptionDate = async (params = {}) => {
    return instance.get("geofences/getGeofenceSubscriptionDate/" + mapQueryParams(params));
};

export const getGeofenceAnalysisVehiclesEntering = async (params = {}) => {
    return instance.get("geofences/getGeofenceAnalysisVehiclesEntering/" + params.geofenceAnalysisId);
}

export const getGeofenceAnalysisUniqueVehiclesEntered = async (params = {}) => {
    return instance.get("geofences/getGeofenceAnalysisUniqueVehiclesEntered/" + params.geofenceAnalysisId);
}

export const getGeofenceAnalysisTotalEntryEvents = async (params = {}) => {
    return instance.get("geofences/getGeofenceAnalysisTotalEntryEvents/" + params.geofenceAnalysisId);
}

export const getGeofenceAnalysisTotalTimeInside = async (params = {}) => {
    return instance.get("geofences/getGeofenceAnalysisTotalTimeInside/" + params.geofenceAnalysisId);
}

export const getGeofenceAnalysisVehiclesEnteringDuration = async (params = {}) => {
    return instance.get("geofences/getGeofenceAnalysisVehiclesEnteringDuration/" + params.geofenceAnalysisId);
}
//#endregion

export const getFilteredReservations = async (params = {}) =>{
  return instance.get("Reservations" + mapQueryParams(params));
}

export const clearReservationFilter = async (params = {}) =>{
  return instance.get("Reservations" + mapQueryParams(params));
}

//#region reservation

export const getReservationReportData = async () => {
  return instance.get("Reservations/ReservationStatus");
};

export const getReservations = async (params = {}) => {
  return instance.get("Reservations" + mapQueryParams(params));
};

export const getReservationById = async (vehicleid) => {
  return instance.get("Reservations/" + vehicleid);
};

export const addReservation = async (bodyParams) => {
  return instance.post("Reservations", JSON.stringify(bodyParams));
};

export const updateReservation = async (bodyParams) => {
  return instance.put(
    "Reservations/" + bodyParams.id,
    JSON.stringify(bodyParams)
  );
};

export const deleteReservation = async (vehicleId) => {
  return instance.delete("Reservations/" + vehicleId);
};

export const getGeofenceAnalyses = async (userId) => {
    return instance.get("geofences/getGeofenceAnalyses");
}

export const getGeofenceToggleArchive = async (bodyParams) => {
    return instance.put("geofences/toggleArchive/" + bodyParams.geofenceId, JSON.stringify(bodyParams));
}

export const getGeofenceAnalysisToggleArchive = async (bodyParams) => {
    return instance.put("geofences/geofenceAnalysisToggleArchive/" + bodyParams.geofenceAnalysisId, JSON.stringify(bodyParams));
}

export const createGeofenceAnalysis = async (bodyParams) => {
    return instance.put("geofences/createGeofenceAnalysis/", JSON.stringify(bodyParams));
}

export const editGeofenceAnalysis = async (bodyParams) => {
    return instance.put("geofences/editGeofenceAnalysis/", JSON.stringify(bodyParams));
}

export const getAvailableVehicles = async (params) => {
  return instance.get("Reservations/AvailableVehicles"+ mapQueryParams(params));
};

export const getAvailableDrivers = async (params) => {
  return instance.get("Reservations/AvailableDrivers" + mapQueryParams(params));
}

//#endregion

//#region QualityCharging
export const getEvoChargeStationsRequest = async (params) => {
  return instance.get("EvoChargingStations" + mapQueryParams(params));
}

export const getEvoChargeStationsByIdRequest = async (chargeStationId) => {
  return instance.get("EvoChargingStations/" + chargeStationId);
}

export const getEvoConnectorByIdRequest = async (data) => {
  return instance.get(`EvoChargingStations/${data.chargeStationId}/${data.connectorNumber}` +  mapQueryParams({from: data.from, to: data.to}));
}

export const startEvoConnectionRequest = async () => {
  return instance.post("EvoChargingStations/connectToChargingStations")
}
export const stopEvoConnectionRequest = async () => {
  return instance.post("EvoChargingStations/disconnectFromChargingStations")
}
//#endregion


//#region DriverIdentification

export const getVehicleNameByIdAsync = async (id) => {
  return instance.get("DriverIdentification/" + id + "/vehicleName");
}

export const getDriverNameByPinAsync = async (routeParams) => {
  return instance.get(`DriverIdentification/${routeParams.vehicleId}/driverName/${routeParams.pin}`);
}

export const getDriversByNameAsync = async (data) => {
  return instance.get(`DriverIdentification/${data.vehicleId}/drivers` +  mapQueryParams({searchTerm: data.searchTerm}));
}

export const assignVehicleToDriverByPinAsync = async (data) => {
  return instance.post(`DriverIdentification/${data.vehicleId}/assignDriverByPin/${data.pin}`);
}

export const assignVehicleToDriverByDriverIdAsync = async (data) => {
  return instance.post(`DriverIdentification/${data.vehicleId}/assignDriverById/${data.driverId}`);
}

export const getVehicleQrCodeById = async (data) => {
  return instance.get(
    `DriverIdentification/${data.vehicleId}/qrCode`, { responseType: 'blob' }
  );
};

export const getSelectedVehiclesPdfQrCode = async (bodyParams) => {
  return instance.post(
    `DriverIdentification/generatePdf`, bodyParams.ids, { responseType: 'blob' }
  );
};

//#endregion
